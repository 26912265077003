import React, { useEffect, useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Aos from 'aos'
import 'aos/dist/aos.css'
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import Header from '../components/Header';






const Lumini = () => {
    /*const [isOpen, setIsOpen] = useState(false);*/
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    /*const mobileMenu = () => {
      setIsOpen(prev => !prev);
    };*/
  
    useEffect(() => {
      Aos.init();
    }, []);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const videoUrl = isMobile ? '/videos/phone_lumini_site.mp4' : '/videos/site_lumini.mp4';
  
  
  return (

    <div className='Content'>
      <Header />
         {/*<header>
        <div className="container">
            
            <nav>
                <ul className={isOpen ? 'active':''}>
                    <li><a href="/">Acasă</a></li>
                    <li><a href="#features">Caracteristici</a></li>
                    <li><a href="/">Detailing</a></li>
                    <li><Link to="/retapitare">Retapitare</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><a href="https://www.facebook.com/plafoaneautoconstanta/" target="_blank" className="facebook-logo" rel="noreferrer">
                    <img src={`${process.env.PUBLIC_URL}../images/icons8-facebook-48.png`} alt="Facebook Logo" /></a></li>
                </ul> 
                <input id="checkbox" onClick={mobileMenu} type="checkbox"/>Menu&#160;
    <label class="toggle" for="checkbox">
        <div id="bar1" class="bars"></div>
        <div id="bar2" class="bars"></div>
        <div id="bar3" class="bars"></div>
    </label>
            </nav>
        </div>
       
    </header>*/}
    <main>
        <section className="hero1" id="hero1">
        <div className="hero1">
            <ReactPlayer
              url={videoUrl} 
              playing
              loop
              muted
              width="100%"
              height="100%"
              playsinline
              className="backgroundVideo"
              onReady={() => console.log('Videoclipul este gata')}
            />
            
            <div className="overlay" />
            <div className="container12">
              <h1 className="title">CLB - Lumini Ambientale</h1>
              <p className="subtitle">Oferim soluții inovatoare pentru...</p>
              <a href="#features" className="cta-button-test">
                Află mai mult
              </a>
            </div>
          </div>
        </section>

        <section id="retapitare" className="section-retapitare">
          <div className="container" id="features">
            <div className="info" data-aos="zoom-in" data-aos-duration="3000">
              <h2>Lumini Ambientale de Interior</h2>
              <p>Oferim servicii profesionale de instalare a luminilor ambientale, garantând transformarea și revitalizarea interiorului vehiculului dumneavoastră. Procesul nostru include următorii pași:</p>
              <ol>
                <li><strong>Consultanță personalizată:</strong> Începem prin a înțelege nevoile și preferințele dumneavoastră, oferind consultanță pentru alegerea tipului de lumini care să se potrivească perfect cu interiorul mașinii dumneavoastră.</li><br />
                <li><strong>Planificarea și designul sistemului de iluminare:</strong> Specialiștii noștri creează un plan detaliat al sistemului de iluminare, asigurându-se că toate zonele dorite sunt acoperite și că lumina este distribuită uniform.</li><br />
                <li><strong>Instalarea luminilor ambientale:</strong> Luminile sunt montate cu precizie în diverse locații ale interiorului, cum ar fi bordul, panourile ușilor și spațiile pentru picioare. Utilizăm echipamente de înaltă calitate pentru a asigura o instalare durabilă și estetică.</li><br />
                <li><strong>Testarea și ajustările finale:</strong> După instalare, sistemul de iluminare este testat riguros pentru a ne asigura că funcționează perfect. Facem ajustările necesare pentru a garanta un finisaj impecabil și o experiență de utilizare optimă.</li><br />
              </ol>
<h3>Beneficii:</h3>
<ul>
    <li><strong>Îmbunătățirea esteticii:</strong> Luminile ambientale transformă interiorul mașinii, oferindu-i un aspect modern și sofisticat.</li><br/>
    <li><strong>Personalizare:</strong> Vă oferim posibilitatea de a alege dintr-o varietate de culori și intensități ale luminii, adaptându-le gusturilor și preferințelor dumneavoastră.</li><br/>   
    <li><strong>Confort și ambianță:</strong> Luminile ambientale contribuie la crearea unei atmosfere plăcute și relaxante în interiorul mașinii, îmbunătățind confortul și experiența de condus.</li><br/>    
</ul>
</div>
<div className="ambient-lights-items">
    {/* <!-- Item Lumini Ambientale cu Galeria Swiper --> */}
    <div className="ambient-lights-item swiper-container" data-aos="fade-up" data-aos-delay="100">
        <Link to="/contact"><button>Cere Oferta</button></Link>

                <div className="swiper mySwiper">
                    <div className="swiper-wrapper">
                    <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]} // Adaugă aici modulele
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
                        
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini1.jpg`} alt='Lumini'/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini43.jpg`} alt='Lumini'/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini3.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini4.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini5.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini6.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini7.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini8.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini9.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini11.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini13.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini14.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini15.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini16.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini17.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini19.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini22.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini26.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini27.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini28.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini35.jpg`} alt="Lumini"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/lumini36.jpg`} alt="Lumini"/></div></SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>

            {/* <!-- Item Stâlpi cu Galeria Swiper --> */}
            <div className="retapitare-item swiper-container" data-aos="fade-up" data-aos-delay="200">
                <h3>Tot nu te-ai convins?</h3>
                <p>Urmareste videoclipul de tip Q&A de mai jos pentru a afla mai multe detalii:</p>
  
              <div className='videoQA'>
              <ReactPlayer
              url="/videos/clb_vid_passat.mp4"
              controls
              width="100%"
              height="auto"
              onReady={() => console.log('Videoclipul Q&A este gata')}
            />
                </div>    
            </div>
        </div>
        <h3>De ce noi?</h3>
                        <p>Echipa noastră de profesioniști este dedicată să ofere servicii de cea mai înaltă calitate, 
                            asigurându-vă că fiecare detaliu este executat perfect. Vă invităm să ne contactați pentru a 
                            discuta mai multe despre cum vă putem transforma interiorul mașinii cu serviciile noastre de 
                            retapitare a plafonului auto, reparatii trapa si lumini ambientale.
                        </p>
    </div>
</section>                
                
          
        <iframe title={'googleMaps'}
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2862.506483377579!2d28.6093712!3d44.1554194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40bae51ea45c8163%3A0xa8fe21d4dfe349b8!2sCLB%20VIP%20DETAILING!5e0!3m2!1sro!2sro!4v1711987675027!5m2!1sro!2sro"
             width="100%"
             height="450"
             // eslint-disable-next-line react/style-prop-object
             style={{border:0}}
             allowFullScreen=""
             loading="lazy"
             referrerPolicy="no-referrer-when-downgrade">
       </iframe>

    </main>
    <footer>
        <div className="footer-pin">
            <p>&copy; 2024 Club Plafoane Auto.</p>
        </div>
    </footer>
    </div>
  )
}
export default Lumini
