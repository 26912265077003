import React, { useEffect /*useState*/ } from 'react'
// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
import Header from '../components/Header';





const Retapitare = () => {
    /*const [isOpen, setIsOpen] = useState(false)
    const mobileMenu= ()=>{
        setIsOpen(prev =>!prev)
    }*/

   

  

    useEffect(()=>{
        Aos.init();
     },[])
  return (
    <div className='Content'>
       <Header />
    <main>
        <section className="hero" id="hero">
            <div className="container11">
                <h1>CLB  Retapitare</h1>
                <p>Oferim soluții inovatoare pentru...</p>
                <a href="#features" className="cta-button-test">Află mai mult</a>
            </div>
        </section>

        <section id="retapitare" className="section-retapitare">
    <div className="container" id="features">
        <div className="info" data-aos="zoom-in" data-aos-duration="3000">
        <h2>Retapitare</h2>
        <p>Oferim servicii profesionale de retapitare a plafonului auto, garantând transformarea și 
            revitalizarea interiorului vehiculului dumneavoastră. Procesul nostru include următorii pași:
        </p>
        <ol>
            <li><strong>Consultanță personalizată:</strong> Începem prin a înțelege nevoile și preferințele dumneavoastră, 
                oferind consultanță pentru alegerea materialului și a culorii care să se potrivească perfect 
                cu interiorul mașinii dumneavoastră.</li><br/>
            <li><strong>Demontarea cu grijă a plafonului actual:</strong> Specialiștii noștri demontează plafonul actual cu atenție, 
                pentru a preveni orice deteriorare a structurii interioare sau a elementelor adiacente.</li><br/>
            <li><strong>Pregătirea și aplicarea noului material:</strong> Suprafața plafonului este curățată și pregătită meticulos 
                pentru aplicarea noului material. Alegem doar materiale de înaltă calitate, care sunt aplicate cu 
                precizie pentru un finisaj impecabil și durabil.</li><br/>
            <li><strong>Montaj și finisare:</strong> După aplicarea materialului nou, plafonul este remontat cu grijă în mașină. 
                Procesul se încheie cu o inspecție atentă și curățarea zonei de lucru, asigurându-ne că mașina 
                dumneavoastră este gata de drum, având un aspect interior ca nou.</li><br/>   
        </ol>
        <h3>Beneficii:</h3>
           <ul>
            <li><strong>Valorificarea mașinii:</strong> Un plafon retapitat nu doar că îmbunătățește aspectul interior, dar poate 
                și crește valoarea de revânzare a vehiculului.</li><br/>
            <li><strong>Personalizare:</strong> Vă oferim posibilitatea de a personaliza interiorul mașinii conform gusturilor dumneavoastră, alegând 
                dintr-o varietate de materiale și culori.</li><br/>   
            <li><strong>Confort și protecție:</strong> Materialele de calitate superioară pe care le folosim contribuie la un confort sporit și oferă o protecție mai bună 
                împotriva razelor UV și a deteriorărilor.</li><br/>    
           </ul>
           </div>
        <div className="retapitare-items">
            {/* <!-- Item Plafon cu Galeria Swiper --> */}
            <div className="retapitare-item swiper-container" data-aos="fade-up" data-aos-delay="100">
                <Link to="/contact"><button>Cere Oferta</button></Link><h3>Plafon</h3>
                <p>Descriere detaliată despre procesul de retapitare a plafonului vehiculului:</p>
    <ol>
        <li><strong>Consultanță personalizată:</strong> Procesul începe cu înțelegerea amănunțită a nevoilor și preferințelor clientului, oferindu-se consultanță pentru selecția materialului și a culorii ideale ce se aliniază cu designul interior al vehiculului.</li>
        <li><strong>Demontarea plafonului actual:</strong> Se demontează cu atenție plafonul existent pentru a evita orice deteriorare suplimentară. Această etapă este crucială pentru pregătirea corectă a suprafeței de lucru.</li>
        <li><strong>Pregătirea și aplicarea materialului nou:</strong> Urmează curățarea și pregătirea suprafeței plafonului, urmată de selecția și aplicarea materialului nou. Materialele de înaltă calitate sunt utilizate pentru a asigura durabilitatea și estetica finisajului.</li>
        <li><strong>Montaj și finisare:</strong> Plafonul retapitat este montat înapoi cu precizie, finalizându-se cu o inspecție de calitate pentru a asigura că rezultatul final îndeplinește standardele noastre înalte. Vehiculul este apoi curățat pentru a îndepărta orice urmă de lucru, lăsând interiorul să strălucească ca nou.</li>
    </ol>
                <div className="swiper mySwiper">
                    <div className="swiper-wrapper">
                    <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]} // Adaugă aici modulele
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
                        
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/porsche_3.jpg`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon2.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon3.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon4.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon5.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon6.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon7.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon8.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon9.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon10.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon11.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon12.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon13.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon14.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon15.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon16.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon17.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon18.JPG`} alt="Plafon"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/plafon19.JPG`} alt="Plafon"/></div></SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>

            {/* <!-- Item Stâlpi cu Galeria Swiper --> */}
            <div className="retapitare-item swiper-container" data-aos="fade-up" data-aos-delay="200">
                <h3>Stâlpi</h3>
                <p>Informații despre serviciile noastre de retapitare pentru stâlpii vehiculului:</p>
    <ol>
        <li><strong>Consultanță inițială:</strong> Analizăm împreună cu clientul starea actuală a stâlpilor și discutăm opțiunile de materiale și culori pentru a se potrivi cu estetica generală a vehiculului.</li>
        <li><strong>Demontarea cu atenție:</strong> Specialiștii noștri îndepărtează cu grijă tapițeria veche sau deteriorată de pe stâlpi, asigurându-se că structura este intactă și pregătită pentru noua tapițerie.</li>
        <li><strong>Selecție și aplicare material:</strong> Clientul poate alege dintr-o varietate de materiale de înaltă calitate pentru retapitare. Noua tapițerie este aplicată cu precizie, garantând o finisare profesională și durabilă.</li>
        <li><strong>Montajul final și verificări:</strong> După aplicarea noii tapițerii, stâlpii sunt montați înapoi cu atenție. Efectuăm verificări finale pentru a asigura că finisajul este perfect și că se aliniază cu standardele noastre de calitate înalte.</li>
    </ol>
                <div className="swiper mySwiper">
                    <div className="swiper-wrapper">
                    <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]} // Adaugă aici modulele
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/stalpi.JPG`} alt="Stâlpi"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/stalpi1.JPG`} alt="Stâlpi"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/stalpi2.JPG`} alt="Stâlpi"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/stalpi3.JPG`} alt="Stâlpi"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/stalpi4.JPG`} alt="Stâlpi"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/stalpi5.JPG`} alt="Stâlpi"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/stalpi6.JPG`} alt="Stâlpi"/></div></SwiperSlide>
                        {/* <!-- Mai multe slide-uri după necesități --> */}
                        </Swiper>
                    </div>
                </div>
            </div>

            {/* <!-- Item Fețe de Uși cu Galeria Swiper --> */}
            <div className="retapitare-item swiper-container" data-aos="fade-up" data-aos-delay="300">
                <h3>Fețe de uși</h3>
                <p>Detalii privind opțiunile de retapitare/revopsire pentru fețele de uși ale autovehiculului:</p>
    <ol>
        <li><strong>Evaluare și consultanță:</strong> Începem cu o evaluare detaliată a fețelor de uși pentru a identifica nevoile specifice ale autovehiculului dumneavoastră. Oferim consultanță pentru a alege între retapitare sau revopsire, în funcție de starea actuală și preferințele dumneavoastră.</li>
        <li><strong>Selecția materialelor:</strong> Fie că optați pentru retapitare sau revopsire, vă oferim o gamă largă de materiale și culori de înaltă calitate, pentru a asigura o potrivire perfectă cu interiorul autovehiculului.</li>
        <li><strong>Procesul de retapitare/revopsire:</strong> Fețele de uși sunt pregătite meticulos, iar procesul de retapitare sau revopsire este efectuat cu atenție de către specialiștii noștri, folosind tehnici avansate pentru a garanta un finisaj superior și de lungă durată.</li>
        <li><strong>Finisaj și verificări finale:</strong> După finalizarea procesului, efectuăm o serie de verificări de calitate pentru a ne asigura că rezultatul final îndeplinește cele mai înalte standarde de calitate și satisface așteptările dumneavoastră.</li>
    </ol>
                <div className="swiper mySwiper">
                    <div className="swiper-wrapper">
                    <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]} // Adaugă aici modulele
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/usa.JPG`} alt="Fețe de uși"/></div></SwiperSlide>
                        <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/usa2.JPG`} alt="Fețe de uși"/></div></SwiperSlide>
                        {/* <!-- Mai multe slide-uri după necesități --> */}
                    </Swiper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    
        {/* <!-- Sectiunea Reparatii Trapă --> */}
        <section id="reparatii-trapa" className="section-reparatii-trapa">
            <div className="container">
                <h2>Reparatii Trapă</h2>
                <div className="reparatii-trapa-items">
                    <div className="reparatii-trapa-item swiper-container" data-aos="fade-up" data-aos-delay="100">
                    <Link to="/contact"><button>Cere Oferta</button></Link><h3>Trapă Normală</h3>
                        <p>Explorarea opțiunilor de reparații pentru trapa normală a vehiculului:</p>
    <ol>
        <li><strong>Diagnosticare completă:</strong> Începem cu o evaluare amănunțită a trapei vehiculului pentru a identifica sursa problemei, fie că este vorba de mecanismele de deschidere/închidere, probleme de etanșeitate sau deteriorări ale structurii.</li>
        <li><strong>Consultanță pentru opțiunile de reparație:</strong> Oferim consultanță personalizată pentru a alege cea mai bună soluție de reparație, luând în considerare atât costurile, cât și eficiența pe termen lung.</li>
        <li><strong>Repararea sau înlocuirea componentelor:</strong> Procedăm cu reparația sau înlocuirea componentelor defecte ale trapei, folosind piese de înlocuire de înaltă calitate pentru a asigura o funcționare optimă și durabilă.</li>
        <li><strong>Testarea și finalizarea lucrărilor:</strong> După finalizarea reparațiilor, trapa este testată amănunțit pentru a ne asigura că funcționează corect și în siguranță. Lucrarea se finalizează cu o inspecție de calitate și curățarea zonei de lucru.</li>
    </ol>
                        <div className="swiper mySwiper">
                            <div className="swiper-wrapper">
                            <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]} // Adaugă aici modulele
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
                                <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/trapa1.JPG`} alt="Reparatii trapa"/></div></SwiperSlide>
                                <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/trapa2.JPG`} alt="Reparatii trapa"/></div></SwiperSlide>
                                <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/trapa3.JPG`} alt="Reparatii trapa"/></div></SwiperSlide>
                                <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/trapa4.JPG`} alt="Reparatii trapa"/></div></SwiperSlide>
                                {/* <!-- Mai multe slide-uri după necesități --> */}
                                </Swiper>
                              </div>
                        </div>
                    </div>
                    <div className="reparatii-trapa-item swiper-container" data-aos="fade-up" data-aos-delay="200">
                        <h3>Trapă Panoramică</h3>
                        <p>Detalii despre cum abordăm repararea trapei panoramice, oferind soluții personalizate:</p>
    <ol>
        <li><strong>Evaluare detaliată:</strong> Procesul nostru începe cu o evaluare amănunțită a trapei panoramice, identificând specificul problemei, de la defecțiuni mecanice la probleme de etanșeitate.</li>
        <li><strong>Consultanță personalizată:</strong> Pe baza evaluării, oferim recomandări personalizate, discutând cu dumneavoastră opțiunile de reparație sau înlocuire, adaptate nevoilor specifice și bugetului disponibil.</li>
        <li><strong>Soluții inovative:</strong> Utilizăm cele mai recente tehnologii și materiale de înaltă calitate pentru a asigura o reparație eficientă și durabilă, oferind atenție fiecărui detaliu.</li>
        <li><strong>Implementare și testare:</strong> După aplicarea soluției alese, trapa panoramică este testată riguros pentru a asigura funcționarea corectă și siguranța în utilizare, finalizând procesul cu o verificare de calitate.</li>
    </ol>
                        <div className="swiper mySwiper">
                            <div className="swiper-wrapper">
                            <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]} // Adaugă aici modulele
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
                                <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/panorama.JPG`} alt="Reparatii trapa"/></div></SwiperSlide>
                                <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/panorama2.JPG`} alt="Reparatii trapa"/></div></SwiperSlide>
                                <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/panorama3.JPG`} alt="Reparatii trapa"/></div></SwiperSlide>
                                <SwiperSlide><div className="swiper-slide"><img src={`${process.env.PUBLIC_URL}../images/panorama4.JPG`} alt="Reparatii trapa"/></div></SwiperSlide>
                                {/* <!-- Mai multe slide-uri după necesități --> */}
                                </Swiper>
                            </div>
                        </div>
                        <h3>De ce noi?</h3>
                        <p>Echipa noastră de profesioniști este dedicată să ofere servicii de cea mai înaltă calitate, 
                            asigurându-vă că fiecare detaliu este executat perfect. Vă invităm să ne contactați pentru a 
                            discuta mai multe despre cum vă putem transforma interiorul mașinii cu serviciile noastre de 
                            retapitare a plafonului auto si reparatii trapa.
                        </p>
                    </div>
                </div>
            </div>
        </section>

    

    

        <iframe title={'googleMaps'}
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2862.506483377579!2d28.6093712!3d44.1554194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40bae51ea45c8163%3A0xa8fe21d4dfe349b8!2sCLB%20VIP%20DETAILING!5e0!3m2!1sro!2sro!4v1711987675027!5m2!1sro!2sro"
             width="100%"
             height="450"
             // eslint-disable-next-line react/style-prop-object
             style={{border:0}}
             allowFullScreen=""
             loading="lazy"
             referrerPolicy="no-referrer-when-downgrade">
       </iframe>


</main>
    <footer>
        <div className="footer-pin">
            <p>&copy; 2024 Club Plafoane Auto.</p>
        </div>
    </footer>
    </div>
  )
}

export default Retapitare