import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();

    const mobileMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Lista paginilor
    const pages = [
        { name: 'Detailing', path: '/' },
        { name: 'Retapitare', path: '/retapitare' },
        { name: 'Lumini Ambientale', path: '/lumini' },
        { name: 'Contact', path: '/contact' },
    ];

    return (
        <header className={scrolled ? 'scrolled' : ''}>
            <div className="container">
                <nav>
                    <ul className={`${isOpen ? 'active' : ''} ${scrolled ? 'scrolled' : ''}`}>
                        {pages.map((page) => {
                            if (page.path === location.pathname) return null; // Ascunde linkul către pagina curentă
                            return (
                                <li key={page.path}>
                                    {page.path.startsWith('/') ? (
                                        <Link to={page.path}>{page.name}</Link>
                                    ) : (
                                        <a href={page.path}>{page.name}</a>
                                    )}
                                </li>
                            );
                        })}
                        <li>
                            <a href="https://www.facebook.com/plafoaneautoconstanta/" 
                               target="_blank" 
                               className="facebook-logo" 
                               rel="noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/images/icons8-facebook-48.png`} alt="Facebook Logo" />
                            </a>
                        </li>
                    </ul>
                    <input id="checkbox" onClick={mobileMenu} type="checkbox" />
                    <label className="toggle" htmlFor="checkbox">
                        <div id="bar1" className="bars"></div>
                        <div id="bar2" className="bars"></div>
                        <div id="bar3" className="bars"></div>
                    </label>
                </nav>
            </div>
        </header>
    );
};

export default Header;
