import React /*{ useState }*/ from 'react'
/*import { Link } from 'react-router-dom'*/
import Header from '../components/Header';

const Gdpr = () => {
    /*const [scrolled, setScrolled] = useState(false)
   const [isOpen, setIsOpen] = useState(false)*/
// Funcție pentru a verifica scroll-ul și a adăuga/elimina clasa 'scrolled'
    const checkScroll= ()=> {
       /* if (window.scrollY > 50) {
           setScrolled(true)
        
        } else {
          setScrolled(false)
        }*/
    
    }
    /*const mobileMenu= ()=>{
        setIsOpen(prev =>!prev)
    }*/
  return (
    <div onScroll={checkScroll}> 
    <Header />
    {/*<header className={ scrolled? 'scrolled':''}>
   <div className="container">
       
       <nav>
           <ul className={isOpen ? 'active':''}>
               <li><Link to="/">Acasă</Link></li>
               <li><Link to="/retapitare">Retapitare</Link></li>
               <li><Link to="/lumini">Lumini Ambientale</Link></li>
               <li><Link to="/contact">Contact</Link></li>
               <li><a href="https://www.facebook.com/plafoaneautoconstanta/" target="_blank" className="facebook-logo" rel="noreferrer">
               <img src={`${process.env.PUBLIC_URL}../images/icons8-facebook-48.png`} alt="Facebook Logo" /></a></li>
           </ul> 
           <button className="hamburger" onClick={mobileMenu} ><span style={{ fontSize: '0.6em' }}>Menu</span>&#9776;</button>
       </nav>
   </div>
  
</header>*/}
        
        <main>
        <section className="privacy-policy" id="privacy-policy">
            <div className="container-politica">
            
                
                    <h1>Politica de Confidențialitate și Protecția Datelor - GDPR</h1>
                    <p><strong>Ultima actualizare:</strong> 02.04.2024</p>
                
                    <h2>Despre GDPR</h2>
                    <p>Regulamentul General privind Protecția Datelor (GDPR) este un cadru legal în vigoare în Uniunea Europeană care guvernează confidențialitatea și protecția datelor personale. Site-ul nostru, <a href="https://clbdetailing.ro">www.clbdetailing.ro</a>, respectă aceste reglementări, asigurându-vă că datele personale sunt tratate în mod sigur și transparent.</p>
                
                    <h2>Colectarea și Utilizarea Datelor Personale</h2>
                    <p>Datele personale pe care le colectăm includ:</p>
                    <ul style={{ color: 'white' }}>
                        <li>Nume și adresa de e-mail, prin intermediul formularului de contact.</li>
                        <li>Informații despre navigarea pe site, cum ar fi adresele IP și cookie-uri, pentru a îmbunătăți experiența dvs. pe site.</li>
                    </ul>
                    <p>Utilizăm aceste informații exclusiv pentru a răspunde solicitărilor dvs. prin intermediul formularului de contact.</p>
                
                    <h2>Siguranța Datelor</h2>
                    <p>Ne angajăm să protejăm securitatea datelor dvs. personale prin măsuri tehnice și organizatorice împotriva accesului neautorizat, modificării, divulgării sau distrugerii neautorizate.</p>
                
                    <h2>Drepturile Dvs.</h2>
                    <p>Conform GDPR, aveți dreptul la acces, rectificare, ștergere („dreptul de a fi uitat”), portabilitatea datelor, restricționarea prelucrării și opoziția la prelucrarea datelor dvs. personale. Pentru a exercita aceste drepturi, vă rugăm să ne contactați la adresa de email: <a href="mailto:clbvipdet@gmail.com">clbvipdet@gmail.com</a>.</p>
                
                    <h2>Cookie-uri și Tehnologii Similare</h2>
                    <p>Utilizăm cookie-uri și tehnologii similare pentru a îmbunătăți și personaliza vizita dvs. pe site-ul nostru, precum și pentru analiză. Aveți controlul asupra cookie-urilor prin setările browserului dvs.</p>
                
                    <h2>Modificări ale Politicii de Confidențialitate</h2>
                    <p>Orice modificări ale acestei politici vor fi comunicate pe această pagină. Vă încurajăm să revizuiți periodic această politică pentru a fi la curent cu modul în care protejăm informațiile pe care le colectăm.</p>
                    {/* <!-- Adaugă mai mult conținut aici după necesități --> */}
            </div>
        </section>
    </main>

    <footer>
        <div className="container">
            <p>&copy; 2024 Club Plafoane Auto.</p>
        </div>
    </footer>
    
    </div>
  )
}

export default Gdpr