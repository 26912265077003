
import './App.css';
import './politica.css'
import './retapitare.css'
import './style-preturi.css'
import './contact1.css'
import './lumini.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Retapitare from './pages/Retapitare';
import Contact from './pages/Contact';
import Gdpr from './pages/Gdpr';
import ScrollToTop from './ScrollToTop';
import Lumini from './pages/Lumini';
function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route path={'/'} element={<Home/>} />
        <Route path={'/retapitare'} element={<Retapitare/>} />
        <Route path={'/lumini'} element={<Lumini/>} />
        <Route path={'/contact'} element={<Contact/>} />
        <Route path={'/politica-confidentialitate'} element={<Gdpr/>} />
      </Routes>
    </Router>
  );
}

export default App;
