import React, { useEffect, useState, useRef } from 'react'

import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
import Header from '../components/Header';

const Home = () => {
//     const container5 = document.getElementsByClassName('container5'); // Selector pentru .container5
//     const header = document.querySelector('header');
//     const ul = document.getElementById('listaNav')
   const container5Ref = useRef(null);
   const [/*scrolled,*/ setScrolled] = useState(false)
   /*const [isOpen, setIsOpen] = useState(false)*/
// Funcție pentru a verifica scroll-ul și a adăuga/elimina clasa 'scrolled'
    const checkScroll= ()=> {
        if (window.scrollY > 50) {
           setScrolled(true)
        
        } else {
          setScrolled(false)
        }
    
    }

    // useEffect(() => {
    //     const handleScroll = () => {
    //       setScrolled(window.scrollY > 50);
    //     };
      
    //     window.addEventListener('scroll', handleScroll);
      
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []); //cu asta se schimba opacitatea la header cand dai scroll

    useEffect(() => {
        const updateBackgroundPosition = () => {
            if (container5Ref.current) { // Corect, folosește .current pentru a accesa elementul
                const scrollPosition = window.scrollY;
                const offsetTopContainer5 = container5Ref.current.offsetTop;
        
                if (scrollPosition >= offsetTopContainer5 - window.innerHeight * 2 && 
                    scrollPosition <= offsetTopContainer5 + container5Ref.current.offsetHeight * 2) {
                    const parallaxSpeed = 0.5;
                    // De asemenea, aici folosește container5Ref.current
                    container5Ref.current.style.backgroundPositionY = `-${(scrollPosition - offsetTopContainer5) * parallaxSpeed}px`;
                }
            }
        };
        
        // Inițializează poziția de fundal la încărcarea componentei
        updateBackgroundPosition();
    
        // Adaugă listener pentru scroll
        window.addEventListener('scroll', updateBackgroundPosition);
        
        // Curăță listener-ul la demontarea componentei
        return () => {
            window.removeEventListener('scroll', updateBackgroundPosition);
        };
    }, []); // Dependințele goale indică faptul că efectul rulează o singură dată la montarea componentei
    
    /*const mobileMenu= ()=>{
        setIsOpen(prev =>!prev)
    }*/
    useEffect(()=>{
       Aos.init();
    },[])
  return (
    <div onScroll={checkScroll}> 
        <Header />
         {/*<header className={ scrolled? 'scrolled':''}>
        <div className="container">
            
            <nav>
                <ul className={isOpen ? 'active':''}>
                    <li><a href="#hero">Acasă</a></li>
                    <li><a href="#features">Caracteristici</a></li>
                    <li><Link to="/retapitare">Retapitare</Link></li>
                    <li><Link to="/lumini">Lumini Ambientale</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><a href="https://www.facebook.com/plafoaneautoconstanta/" target="_blank" className="facebook-logo" rel="noreferrer">
                    <img src={`${process.env.PUBLIC_URL}../images/icons8-facebook-48.png`} alt="Facebook Logo" /></a></li>
                </ul> 
                <button className="hamburger" onClick={mobileMenu} ><span style={{ fontSize: '0.6em' }}>Menu</span>&#9776;</button>
            </nav>
        </div>
       
    </header>*/}

    <main>
        <section className="hero" id="hero">
            <div className="container11">
                <h1>Bine ați venit la CLB Detailing si Retapitare</h1>
                <p>Oferim soluții inovatoare pentru...</p>
                <a href="#features" className="cta-button-test">Află mai mult</a>
            </div>
        </section>

        <section className="parallax-tarife" id="features" data-aos="fade-right" data-aos-duration="2000">
            <div className="overlay"></div>
            <div className="container5" ref={container5Ref}>
                <h2>Tarife Servicii</h2>
                <p>Lista serviciilor și a prețurilor aferente depinde in functie de autoturism</p>
        
                <div className="pricing-table">
                    <div className="pricing-card">
                        <div className="pricing-card-header">
                            <h3 className="pricing-card-title">Detailing Interior</h3>
                        </div>
                        <div className="pricing-card-price"><button><Link to="/contact">Cere Oferta</Link></button></div>
                        <div className="pricing-card-features">
                            <ul>
                                <li>Curatarea completa si in detaliu a fiecarui element al interiorului masinii, pentru un aspect final ca nou, sau in unele cazuri, chiar mai bun;</li>
                                <li>Spalare exterior cu spuma activa si uscare;</li>
                                <li>Suflare cu aer si aspirare praf toate compartimentele si bord;</li>
                                <li>Curatare in profunzime scaune, mocheta, covorase, plafon si portbagaj cu injectie-extractie;</li>
                                <li>Degresare elemente piele/textil/plastic/alcantara;</li>
                                <li>Curatare completa geamuri interior/exterior, displayuri si ornamente;</li>
                                <li>Curatare zone interioare praguri usi, portbagaj;</li>
                                <li>Hidratare elemente piele/vinyl;</li>
                                <li>Tratament revitalizare piese plastic/piano black;</li>
                                <li>Igienizare habitaclu cu ozon</li>
                                <li>Durata 24 ore (include uscarea completa a tapiteriilor). Durata poate fi mai scurta in functie de materialele interiorului si gradul de murdarire;</li>
                            </ul>
                        </div>
                    </div>
        
                    {/* <!-- Adaugă și alte carduri asemănătoare --> */}
        
                    <div className="pricing-card">
                        <div className="pricing-card-header">
                            <h3 className="pricing-card-title">Detailing Interior Quick</h3>
                        </div>
                        <div className="pricing-card-price"></div>
                        <div className="pricing-card-features">
                            <ul>
                                <li>Curatarea de intretinere periodica a masinii, cu atentie la fiecare detaliu;</li>
                                <li>Suflare cu aer si aspirare praf toate compartimentele;</li>
                                <li>Curatare scaune, covorase, portbagaj;</li>
                                <li>Curatare elemente piele/textil/plastic;</li>
                                <li>Curatare completa geamuri interior/exterior, display-uri si ornamente;</li>
                                <li>Durata: 3 ore;</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
        <section className="parallax-tarife2" id="detailing-exterior" data-aos="fade-up" data-aos-duration="2000">
            <div className="container2">
                
                <div>
                      <h2>Detailing Exterior</h2>
                <p>Cel mai bun tratament pe care îl putem oferi mașinii tale, detailing-ul exterior complet este un proces de durată, cu multe beneficii. Mașina își va arăta aspectul maxim pe care îl poate oferi;</p>
                
                </div>
               
                <div className="pricing-table">
                <button><Link to="/contact">Cere Oferta</Link></button>
                    {/* <!-- Card pentru Detailing Exterior Complet Cu Polish --> */}
                    <div className="pricing-card">
                        <div className="pricing-card-header">
                            <h3 className="pricing-card-title">Detailing Exterior Complet Cu Polish</h3>
                        </div>
                        <div className="pricing-card-price"></div>
                        <div className="pricing-card-features">
                            <ul>
                            <li>Spălare caroserie spumă activă</li>
        <li>Curățare în detaliu motor</li>
        <li>Decontaminare chimică și fizică a caroseriei</li>
        <li>Polishare unu sau mai multe etape pentru îndepărtarea zgârieturilor fine și redarea strălucirii</li>
        <li>Aplicare strat de ceară sau sealant pentru protecție de lungă durată</li>
        <li>Curățare și revitalizare jante și anvelope</li>
        <li>Tratament geamuri exterioare pentru hidrofobizare</li>
        <li>Polishare faruri pentru îmbunătățirea vizibilității și aspectului</li>
        <li>Verificare și curățare detaliată a tuturor spațiilor greu accesibile</li>
        <li><b>**Durata: 24 ore</b></li>
                            </ul>
                        </div>
                        <h2><b><u>Polish Faruri</u></b></h2>
                            <p>Polișarea farurilor este un serviciu esențial pentru orice autoturism, asigurând nu doar un aspect estetic îmbunătățit, dar și o siguranță sporită pe timp de noapte.
                             Datorită tehnicii noastre avansate, eliminăm zgârieturile superficiale și redăm claritatea originală a farurilor,
                             prelungindu-le durata de viață. 
                            </p>
                        <h3>Pret - 150lei/Far</h3>
                            <p>Beneficii:</p> 
                            <ul>
                                <li>Îmbunătățirea vizibilității și siguranței pe timp de noapte</li>
                                <li>Aspect îmbunătățit al autovehiculului</li>
                                <li>Costuri reduse comparativ cu înlocuirea farurilor</li>
                            </ul>
                            <br/>
                            <p>Serviciul nostru de polișare faruri este rapid și eficient, realizat de tehnicieni calificați.
                                 Contactați-ne pentru programări și detalii suplimentare.
                            </p>
                                  

                        
                    </div>
        
                    {/* <!-- Alte carduri asemănătoare pentru celelalte servicii --> */}
                    
                </div>
            </div>
        </section>
        
        <iframe title={'googleMaps'}
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2862.506483377579!2d28.6093712!3d44.1554194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40bae51ea45c8163%3A0xa8fe21d4dfe349b8!2sCLB%20VIP%20DETAILING!5e0!3m2!1sro!2sro!4v1711987675027!5m2!1sro!2sro"
             width="100%"
             height="450"
             // eslint-disable-next-line react/style-prop-object
             style={{border:0}}
             allowFullScreen=""
             loading="lazy"
             referrerPolicy="no-referrer-when-downgrade">
       </iframe>

    {/* <!-- Încarcă Google Maps JavaScript API --> */}
    
        
    </main>

    <footer>
        <div className="container">
            <p>&copy; 2024 Club Plafoane Auto.</p><Link to="/politica-confidentialitate">Politica de confidentialitate</Link>
        </div>
    </footer>

    {/* <script src="../script.js"></script>
    <script src="../index.js"></script>
    <script>
        document.querySelector('.hamburger').addEventListener('click', function() {
            document.querySelector('nav ul').classList.toggle('active')
        });
        </script> */}
    </div>
  )
}

export default Home