import React ,{useState} from 'react'
import axios from 'axios'
/*import { Link } from 'react-router-dom';*/
import Header from '../components/Header';

const Contact = () => {
    const [nume,setNume] = useState('')
    const [email,setEmail] = useState('')
    const [message,setMessage] = useState('')
    /*const [isOpen, setIsOpen] = useState(false)*/

    const submitForm = async(e)=>{

        e.preventDefault()
       console.log('merge cand apesi')
        try {
           const{data} = await axios.post('https://serverclb.codebear.ro/send-email',{
            nume,
            email,
            message,
        });
        console.log('Răspuns server:', data);
        window.alert('Email trimis cu succes')
        } catch (error) {
            console.error('Eroare la trimiterea emailului:', error);
            window.alert('Oooops a fost o eroare. Va rugam incercati mai tarziu...')
        }
    };
    /*const mobileMenu= ()=>{
        setIsOpen(prev =>!prev)
    }*/
  return (
    <section id="contact">
            
    <div className="contact-flex-container">
        <Header />
    {/*<header>
        <div className="container">
            
            <nav>
                <ul className={isOpen ? 'active':''}>
                    <li><Link to="/">Acasă</Link></li>
                    <li><Link to="/retapitare">Retapitare</Link></li>
                    <li><Link to="/">Detailing</Link></li>
                    <li><Link to="/lumini">Lumini Ambientale</Link></li>
                    <li><a href="https://www.facebook.com/plafoaneautoconstanta/" target="_blank" className="facebook-logo" rel="noreferrer">
                    <img src={`${process.env.PUBLIC_URL}../images/icons8-facebook-48.png`} alt="Facebook Logo" /></a></li>
                </ul> 
                <button className="hamburger" onClick={mobileMenu} ><span style={{ fontSize: '0.6em' }}>Menu</span>&#9776;</button>
            </nav>
        </div>
       
    </header>*/}

        

        <div className="contact-left">
            <div className="contact-info">
                <div className="contact1">
                <h3><u>Ne poti contacta aici:</u></h3>
            </div>
                <div className="contact12">
                <img src={`${process.env.PUBLIC_URL}../images/phone-svgrepo-com.svg`} alt="Phone" className="svg-icon"/>
                    <p>0723 249 505</p>
                    <p></p>
                </div>
                <div className="contact13">
                    <img src={`${process.env.PUBLIC_URL}../images/whatsapp-svgrepo-com.svg`} alt="WhatsApp" className="svg-icon"/>
                    <p>0723 249 505</p>
                </div>
            </div>
            <div className="contact2">
                <h3><u>Urmareste-ne pe Social Media</u></h3>
                <div className="contact14">
                    
                        <a href="https://www.facebook.com/clubautoconstanta/"> <img src={`${process.env.PUBLIC_URL}../images/icons8-facebook-48.png`} alt="facebook" className="svg-icon"/></a>
                    
                        <a href="https://www.instagram.com/club_plafoane_clb/"> <img src={`${process.env.PUBLIC_URL}../images/instagram-1-svgrepo-com.svg`} alt="instagram" className="svg-icon"/></a>
                    
                    
                        <a href="https://www.tiktok.com/@clb_vip_detailing_auto"> <img src={`${process.env.PUBLIC_URL}../images/tiktok.png`} alt="tiktok" className="svg-icon"/></a>
                    
                </div>
            </div>
        </div>
        <div className="contact-middle">
            <h2>SAU</h2>
        </div>
        <div className="container contact-container">
            <h3>Scrie-ne un mesaj acum.</h3>
            <form id="contact-form" 
            className="contact-form" 
            onSubmit={submitForm}
            // action="http://localhost:3000/send-email" 
            // method="POST"
            >
                {/* <!-- Honeypot to stop spam--> */}
                <input type="text" name="_honey" style={{display: 'none'}}/>

                <div className="form-group"> 
                    <label htmlFor="name">Nume:<font color="red">*</font></label>
                    <input type="text" id="name" onChange={(e)=>setNume(e.target.value)} name="Name" placeholder="Introdu numele aici" required/>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:<font color="red">*</font></label>
                    <input type="email" id="email" onChange={(e)=>setEmail(e.target.value)} name="Email" placeholder="Introdu email-ul tau pe care vrei sa fii contactat" required/>
                </div>
                <div className="form-group">
                    <label htmlFor="message">Mesaj:<font color="red">*</font></label>
                    <textarea id="message" onChange={(e)=>setMessage(e.target.value)} name="Message" rows="5" placeholder="Introdu mesajul tau pentru noi" required></textarea>
                </div>
                <button type="submit" className="submit-btn">Send Message</button>
            </form>
        </div>
    </div>
    <footer>
        <div className="footer-pin">
            <p>&copy; 2024 Club Plafoane Auto.</p>
        </div>
    </footer>
</section>


  )
}

export default Contact